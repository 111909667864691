<template>
  <div>
    <div class="page-title">
      <h3>System Configuration</h3>
      <div class="page-breadcrumb">
        <ol class="breadcrumb">
          <li>
            <router-link
              :to="{name: 'home'}"
            >
              Home
            </router-link>
          </li>
          <li><a href="#">Management</a></li>
          <li class="active">
            System
          </li>
        </ol>
      </div>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h4 class="panel-title">
                Branches
              </h4>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="branchList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr>
                      <th>Sign Up ID</th>
                      <th>Name</th>
                      <th>Manager</th>
                      <th>Location</th>
                      <th>YES Bank LG Code</th>
                      <th>Enabled</th>
                      <th>Date Added</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="panel panel-white">
            <div class="panel-heading clearfix">
              <h4 class="panel-title">
                Statuses
              </h4>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="statusList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Enabled</th>
                      <th>Month To Date Report</th>
                      <th>Last Month Report</th>
                      <th>Seen By</th>
                      <th>Form Editable By</th>
                      <th>Show All Fields To</th>
                      <th>Dashboard Daily</th>
                      <th>Dashboard All Time</th>
                      <th>Workflow Order</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="branchModal"
        aria-hidden="true"
        class="modal fade"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span
                  aria-hidden="true"
                >&times;</span>
              </button>
              <h2 class="modal-title text-center">
                Branch Details
              </h2>
            </div>
            <div class="modal-body">
              <div class="row m-b-lg">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="form-group col-sm-offset-1 col-sm-3">
                      <label class="form-details-label">ID:</label>
                      <p class="text-muted">
                        {{ branchDetails.branchId }}
                      </p>
                    </div>
                    <div class="form-group col-sm-4">
                      <label class="form-details-label">Managers:</label>
                      <ul
                        id="managers"
                        class="text-muted"
                      >
                        <li
                          v-for="(manager, index) in branchDetails.managers"
                          :key="'manager' + index"
                        >
                          {{ manager }}
                        </li>
                      </ul>
                    </div>
                    <div class="form-group col-sm-3">
                      <label class="form-details-label">Date Added: </label>
                      <p class="text-muted">
                        {{ prettyPrint(branchDetails.createdAt, 'Date') }}
                      </p>
                    </div>
                  </div>
                  <form
                    id="branchDetails"
                    class="row"
                    style="margin-top: 25px"
                  >
                    <div class="form-group col-sm-offset-1 col-sm-3">
                      <label class="has-float-label">
                        <input
                          v-model="branchDetails.name"
                          placeholder=" "
                          type="text"
                        >
                        <span class="float-label">
                          Name
                          <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          />
                        </span>
                      </label>
                    </div>
                    <div class="form-group col-sm-4">
                      <label class="has-float-label">
                        <input
                          v-model="branchDetails.location"
                          placeholder=" "
                          type="text"
                        >
                        <span class="float-label">
                          Location
                          <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          />
                        </span>
                      </label>
                    </div>
                    <div class="form-group col-sm-3">
                      <div class="row">
                        <span class="col-xs-12 m-b-xs">Enabled: </span>
                        <toggle-button
                          v-model="branchDetails.isEnabled"
                          :sync="true"
                          class="col-xs-12"
                          color="#22baa0"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-default"
                data-dismiss="modal"
                type="button"
              >
                Close
              </button>
              <button
                class="btn btn-default btn-success"
                type="button"
                @click.stop.prevent="updateBranchDetails"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="statusModal"
        aria-hidden="true"
        class="modal fade"
        role="dialog"
        tabindex="-1"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button
                aria-label="Close"
                class="close"
                data-dismiss="modal"
                type="button"
              >
                <span
                  aria-hidden="true"
                >&times;</span>
              </button>
              <h2 class="modal-title text-center">
                Status Details
              </h2>
            </div>
            <div class="modal-body">
              <div class="row m-b-lg">
                <div class="col-sm-12">
                  <div
                    class="row"
                    style="margin-top: 25px"
                  >
                    <div class="form-group col-md-offset-1 col-md-4 col-sm-5 col-xs-9">
                      <label class="has-float-label">
                        <input
                          v-model="statusDetails.name"
                          v-validate="'required'"
                          :class="errors.has('statusDetails.name') ? 'error' : ''"
                          data-vv-as="status name"
                          name="statusDetails.name"
                          placeholder=" "
                          type="text"
                        >
                        <span class="float-label">
                          Name
                          <i
                            aria-hidden="true"
                            class="fas fa-asterisk text-danger"
                            style="font-size: 7px; vertical-align: text-top;"
                          />
                        </span>
                        <span
                          v-show="errors.has('statusDetails.name')"
                          class="help text-danger"
                        >{{ errors.first('statusDetails.name') }}</span>
                      </label>
                    </div>
                    <div class="form-group col-xs-2">
                      <span class="col-xs-12 m-b-xs">Enabled: </span>
                      <toggle-button
                        v-model="statusDetails.isEnabled"
                        :sync="true"
                        class="col-xs-12"
                        color="#22baa0"
                      />
                    </div>
                    <div class="form-group col-sm-5 col-xs-12">
                      <label class="form-details-label">Date Added:</label>
                      <p class="text-muted">
                        {{ prettyPrint(statusDetails.createdAt, 'Date') }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-offset-1 col-md-5 col-sm-6">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="statusDetails.currentMonth"
                          :allow-empty="true"
                          :class="{ 'placeholder-shown': (!statusDetails.currentMonth || statusDetails.currentMonth === '') }"
                          :limit="1"
                          :multiple="true"
                          :options="_getTitles"
                          :placeholder="null"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                        />
                        <span class="float-label">Current Month Report:</span>
                        <span
                          v-show="errors.has('statusDetails.currentMonth')"
                          class="help text-danger"
                        >{{ errors.first('statusDetails.currentMonth') }}</span>
                      </label>
                    </div>
                    <div class="form-group col-md-5 col-sm-6">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="statusDetails.lastMonth"
                          :allow-empty="true"
                          :class="{ 'placeholder-shown': (!statusDetails.lastMonth || statusDetails.lastMonth === '') }"
                          :limit="1"
                          :multiple="true"
                          :options="_getTitles"
                          :placeholder="null"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                        />
                        <span class="float-label">Last Month Report</span>
                        <span
                          v-show="errors.has('statusDetails.lastMonth')"
                          class="help text-danger"
                        >{{ errors.first('statusDetails.lastMonth') }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-offset-1 col-md-3 col-sm-4">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="statusDetails.selectable"
                          :allow-empty="true"
                          :class="{ 'placeholder-shown': (!statusDetails.selectable || statusDetails.selectable === '') }"
                          :limit="1"
                          :multiple="true"
                          :options="_getTitles"
                          :placeholder="null"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                        />
                        <span class="float-label">Show Status:</span>
                        <span
                          v-show="errors.has('statusDetails.selectable')"
                          class="help text-danger"
                        >{{ errors.first('statusDetails.selectable') }}</span>
                      </label>
                    </div>
                    <div class="form-group col-md-4 col-sm-4">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="statusDetails.editable"
                          :allow-empty="true"
                          :class="{ 'placeholder-shown': (!statusDetails.editable || statusDetails.editable === '') }"
                          :limit="1"
                          :multiple="true"
                          :options="_getTitles"
                          :placeholder="null"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                        />
                        <span class="float-label">Allowed To Edit Form Fields:</span>
                        <span
                          v-show="errors.has('statusDetails.editable')"
                          class="help text-danger"
                        >{{ errors.first('statusDetails.editable') }}</span>
                      </label>
                    </div>
                    <div class="form-group col-md-3 col-sm-4">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="statusDetails.allFields"
                          :allow-empty="true"
                          :class="{ 'placeholder-shown': (!statusDetails.allFields || statusDetails.allFields === '') }"
                          :limit="1"
                          :multiple="true"
                          :options="_getTitles"
                          :placeholder="null"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                        />
                        <span class="float-label">Show All Form Fields:</span>
                        <span
                          v-show="errors.has('statusDetails.allFields')"
                          class="help text-danger"
                        >{{ errors.first('statusDetails.allFields') }}</span>
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-md-offset-1 col-md-5 col-sm-6">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="statusDetails.alltime"
                          :allow-empty="true"
                          :class="{ 'placeholder-shown': (!statusDetails.alltime || statusDetails.alltime === '') }"
                          :limit="1"
                          :multiple="true"
                          :options="alltimeTitlesList"
                          :placeholder="null"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                        />
                        <span class="float-label">Dashboard All Time:</span>
                        <span
                          v-show="errors.has('statusDetails.alltime')"
                          class="help text-danger"
                        >{{ errors.first('statusDetails.alltime') }}</span>
                      </label>
                    </div>
                    <div class="form-group col-md-5 col-sm-6">
                      <label class="has-float-label">
                        <VueMultiselect
                          v-model="statusDetails.daily"
                          :allow-empty="true"
                          :class="{ 'placeholder-shown': (!statusDetails.daily || statusDetails.daily === '') }"
                          :limit="1"
                          :multiple="true"
                          :options="dailyTitlesList"
                          :placeholder="null"
                          :show-labels="false"
                          label="name"
                          track-by="id"
                        />
                        <span class="float-label">Dashboard Daily</span>
                        <span
                          v-show="errors.has('statusDetails.daily')"
                          class="help text-danger"
                        >{{ errors.first('statusDetails.daily') }}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-default"
                data-dismiss="modal"
                type="button"
              >
                Close
              </button>
              <button
                id="updateStatusButton"
                class="btn btn-default btn-success"
                type="button"
                @click.stop.prevent="updateStatusDetails"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div><!-- Main Wrapper -->
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {buttons} from "@/constants";
import VueMultiselect from "vue-multiselect";
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from "../../graphql/mutations";

export default {
  name: "SystemConfiguration",
  components: {
    VueMultiselect
  },
  data() {
    return {
      branchTable: null,
      statusTable: null,
      branchDetails: {
        isEnabled: false
      },
      statusDetails: {
        isEnabled: false,
        currentMonth: [],
        lastMonth: [],
        editable: [],
        allFields: [],
        selectable: [],
        daily: [],
        alltime: []
      }
    };
  },
  beforeRouteLeave(to, from, next) {
    let statusModal = $("#statusModal");
    if ((statusModal.data('bs.modal') || {}).isShown) {
      statusModal.modal('hide');
      document.getElementById('statusList').scrollIntoView({behavior: 'instant'});
      next(false);
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([
      "getBranches",
      "getTitles",
      "getStatuses"
    ]),
    _getTitles: function () {
      return _.sortBy(this.getTitles, 'name');
    },
    dailyTitlesList: function () {
      let userJobs = _.map(this.statusDetails.alltime, 'access');
      return _.sortBy(_.filter(this.getTitles, (title => {
        return !_.includes(userJobs, title.access);
      })), 'name');
    },
    alltimeTitlesList: function () {
      let userJobs = _.map(this.statusDetails.daily, 'access');
      return _.sortBy(_.filter(this.getTitles, (title => {
        return !_.includes(userJobs, title.access);
      })), 'name');
    }
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');

    $(':radio').click((e) => {
      e.preventDefault();
      e.stopPropagation();

      _.set(this, e.currentTarget.name, e.currentTarget.value);
    });

    this.createBranchesDatatable();
    this.createStatusesDatatable();
  },
  methods: {
    ...mapMutations([
      "UPDATE_STATUSES"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    prettyPrint: function (value, type) {
      if (type === 'Date') {
        if (_.includes(value, 'T')) {
          return moment(value).format('dddd, MMMM Do YYYY h:mm A');
        } else if (!value || value === '') {
          return '';
        } else {
          return moment(value, "YYYY-MM-DD").format('DD-MM-YYYY');
        }
      }
    },
    createBranchesDatatable: function () {
      this.branchTable = $('#branchList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.getBranches,
        rowId: 'id',
        columns: [
          {
            data: 'number'
          },
          {
            data: 'name'
          },
          {
            data: 'managers'
          },
          {
            data: 'location'
          },
          {
            data: 'yesLGCode'
          },
          {
            data: 'isEnabled'
          },
          {
            data: 'createdAt'
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              return _.padStart(data, 4, '0');
            },
            targets: 0
          },
          {
            render: (data) => {
              if (_.includes(data, 'T')) {
                return moment(data).format('dddd, MMMM Do YYYY h:mm A');
              } else if (!data || data === '') {
                return '';
              } else {
                return moment(data, "YYYY-MM-DD").format('DD-MM-YYYY');
              }
            },
            targets: 6
          }
        ],
        buttons: buttons,
        search: {
          regex: true,
          smart: false
        },
        order: [[0, "asc"]]
      });

      this.branchTable.buttons()
          .container()
          .appendTo('#branchList_wrapper .col-sm-6:eq(0)');

      this.setupBranchModal();
    },
    createStatusesDatatable: function () {
      this.statusTable = $('#statusList').DataTable({
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: this.getStatuses,
        rowId: 'id',
        columns: [
          {
            data: 'name'
          },
          {
            data: 'isEnabled'
          },
          {
            data: 'currentMonth',
            defaultContent: ''
          },
          {
            data: 'lastMonth',
            defaultContent: ''
          },
          {
            data: 'selectable',
            defaultContent: ''
          },
          {
            data: 'editable',
            defaultContent: ''
          },
          {
            data: 'allFields',
            defaultContent: ''
          },
          {
            data: 'daily',
            defaultContent: ''
          },
          {
            data: 'alltime',
            defaultContent: ''
          },
          {
            data: 'workflow',
            defaultContent: ''
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              let titles = '';
              _.forEach(data, (titleType) => {
                if (_.isObjectLike(titleType)) {
                  titles += titleType.access + ', ';
                } else {
                  let titleName = _.result(_.find(this.getTitles, (title) => {
                    return title.access === titleType;
                  }), 'name');
                  if (titleName) {
                    titles += titleName + ', ';
                  }
                }
              });
              if (titles !== '') {
                titles = titles.substring(0, titles.length - 2);
              }
              return titles;
            },
            targets: [2, 3, 4, 5, 6, 7, 8]
          }
        ],
        buttons: buttons,
        search: {
          regex: true,
          smart: false
        },
        order: [[9, "asc"]]
      });

      this.statusTable.buttons()
          .container()
          .appendTo('#statusList_wrapper .col-sm-6:eq(0)');

      this.setupStatusModal();
    },
    setupBranchModal: function () {
      //TODO: WRITE LOGIC
    },
    setupStatusModal: function () {
      $('#statusList tbody').on('click', 'tr', (e) => {
        this.statusDetails = {
          isEnabled: false
        };
        this.statusDetails = _.cloneDeep(this.statusTable.row(e.currentTarget).data());
        if (this.statusDetails) {
          this.statusDetails.currentMonth = _.filter(this.getTitles, (title) => {
            return _.includes(this.statusDetails.currentMonth, title.access);
          });
          this.statusDetails.lastMonth = _.filter(this.getTitles, (title) => {
            return _.includes(this.statusDetails.lastMonth, title.access);
          });
          this.statusDetails.selectable = _.filter(this.getTitles, (title) => {
            return _.includes(this.statusDetails.selectable, title.access);
          });
          this.statusDetails.editable = _.filter(this.getTitles, (title) => {
            return _.includes(this.statusDetails.editable, title.access);
          });
          this.statusDetails.allFields = _.filter(this.getTitles, (title) => {
            return _.includes(this.statusDetails.allFields, title.access);
          });
          this.statusDetails.alltime = _.filter(this.getTitles, (title) => {
            return _.includes(this.statusDetails.alltime, title.access);
          });
          this.statusDetails.daily = _.filter(this.getTitles, (title) => {
            return _.includes(this.statusDetails.daily, title.access);
          });

          $("#statusModal").modal('show');
        } else {
          this.statusDetails = {};
        }
      });
    },
    updateBranchDetails: function () {
      //TODO: WRITE LOGIC
    },
    updateStatusDetails: function () {
      $('#updateStatusButton').attr('disabled', true);
      this.$validator.validateAll()
          .then((result) => {
            if (result) {
              this.validationSuccessfulStatusDetails();
            } else {
              $('#updateStatusButton').attr('disabled', false);
              let firstErrorInput = $('input.error:first')[0] || $('.multiselect.error:first') [0];
              if (firstErrorInput) {
                firstErrorInput.scrollIntoView({behavior: 'instant'});
                firstErrorInput.focus();
              }
              if (this.errors.items && this.errors.items.length > 1) {
                this.setMessage('v-notify-error', "Oops!", "Please correct the " + this.errors.items.length + " errors on the page.");
              } else {
                let errorInputName = firstErrorInput.name || firstErrorInput.dataset.vvAs || 'form';
                if (errorInputName && errorInputName !== '') {
                  errorInputName = _.upperCase(_.replace(errorInputName, "statusDetails.", ""));
                  this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
                } else {
                  this.setMessage('v-notify-error', "Sorry!", "Errors on the page!");
                }
              }
            }
          })
          .catch(() => {
            $('#updateStatusButton').attr('disabled', false);
            let firstErrorInput = $('input.error:first')[0] || $('.multiselect.error:first') [0];
            if (firstErrorInput) {
              firstErrorInput.scrollIntoView({behavior: 'instant'});
              firstErrorInput.focus();
            }
            if (this.errors.items && this.errors.items.length > 1) {
              this.setMessage('v-notify-error', "Oops!", "Please correct the " + this.errors.items.length + " errors on the page.");
            } else {
              let errorInputName = firstErrorInput.name || firstErrorInput.dataset.vvAs || 'page';
              if (errorInputName && errorInputName !== '') {
                errorInputName = _.upperCase(_.replace(errorInputName, "statusDetails.", ""));
                this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
              } else {
                this.setMessage('v-notify-error', "Sorry!", "Errors in the page!");
              }
            }
          });
    },
    async validationSuccessfulStatusDetails() {
      try {
        let updateDetails = _.omit(this.statusDetails, ['logs', 'version', '__typename', 'updatedAt', 'justifications', 'workflow']);
        updateDetails.currentMonth = _.map(updateDetails.currentMonth, 'access');
        updateDetails.lastMonth = _.map(updateDetails.lastMonth, 'access');
        updateDetails.selectable = _.map(updateDetails.selectable, 'access');
        updateDetails.editable = _.map(updateDetails.editable, 'access');
        updateDetails.allFields = _.map(updateDetails.allFields, 'access');
        updateDetails.alltime = _.map(updateDetails.alltime, 'access');
        updateDetails.daily = _.map(updateDetails.daily, 'access');
        _.forEach(updateDetails.editable, (title) => {
          if (!_.includes(updateDetails.allFields, title)) {
            updateDetails.allFields.push(title);
          }
        });
        const {
          data: {updateStatus}
        } = await API.graphql(graphqlOperation(mutations.updateStatus, {
          input: updateDetails
        }));

        let oldStatuses = _.cloneDeep(this.getStatuses);
        let statusIndex = _.findIndex(oldStatuses, (oldStatus) => {
          return oldStatus.id === updateStatus.id;
        });
        if (statusIndex !== -1) {
          oldStatuses[statusIndex] = updateStatus;
          this.UPDATE_STATUSES(oldStatuses);
        }

        $('#updateStatusButton').attr('disabled', false);
        this.setMessage('v-notify-success', "Nice!", "The status was updated successfully!");
        this.statusTable.row("#" + updateStatus.id)
            .invalidate()
            .data(updateStatus)
            .draw();
        $("#statusModal").modal('hide');
      } catch (e) {
        $('#updateStatusButton').attr('disabled', false);
        this.setMessage('v-notify-error', "Error!", e);
      }
    }
  }
}
</script>

<style scoped>

</style>
